import { createSlice } from '@reduxjs/toolkit';
import { audioRecordsApi } from '../api/audioRecordsApi';


const initialState = {
  records: null,
  count: null,
};

const audioRecordsSlice = createSlice({
  name: 'audioRecords',
  initialState,
  reducers: {
    clearAudioRecords: (state) => {
      state.records = null;
      state.count = null;
    },
    resetAudioRecords: (state) => {
      state.records = initialState.records;
      state.count = initialState.count;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(audioRecordsApi.endpoints.getAudioRecords.matchFulfilled, (state, action) => {
        state.audioRecords = action.payload.records;
        state.audioRecordsCount = action.payload.count;
    })
    .addMatcher(audioRecordsApi.endpoints.getAudioRecords.matchRejected, (state, action) => {
      // state.records = null;
      // state.count = null;
    })
  },
});

export const { getAudioRecords } = audioRecordsSlice.actions;
export const { resetAudioRecords } = audioRecordsSlice.actions;

export default audioRecordsSlice.reducer;
