import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import dayjs from "dayjs";

const BASE_URL = process.env.REACT_APP_API_BASE_URL
const statuses = {
  1: { name: "New" },
  2: { name: "In progress" },
  3: { name: "Closed" },
};


export const ticketsApi = createApi({
  reducerPath: 'ticketsApi',
  baseQuery: fetchBaseQuery({ 
    baseUrl: BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const accessToken = getState().auth.access;
      if (accessToken) {
        headers.set('Authorization', `Bearer ${accessToken}`);
      }
      return headers;
    },
    timeout: 5000,
    onQueryError: (error) => {
      console.error('An error occurred:', error);
    },
  }),
  endpoints: (builder) => ({
    getTickets: builder.query({
      query: ({offset, limit, call_date_start, call_date_end, phone, theme, status, is_test}) => {
        const dateStart = call_date_start ? `&call_date_start=${call_date_start} 00:00:00` : '';
        const dateEnd = call_date_end ? `&call_date_end=${call_date_end} 23:59:59` : '';
        const phoneQuery = phone ? `&phone=${phone}` : '';
        const themeQuery = theme ? `&theme_id=${theme}` : '';
        const statusQuery = status ? `&status_id=${status}` : '';
        const isTest = `&is_test=${is_test}`;
        return `milk_alliance/tickets?limit=${offset}&offset=${offset*(limit-1)}${dateStart}${dateEnd}${phoneQuery}${themeQuery}${statusQuery}${isTest}`
      },
      transformResponse: (response) => {
        const tickets = response.tickets.map((ticket) => {
          return { 
            id: ticket.id, 
            call_date: dayjs(ticket.call_date).format("DD.MM.YYYY HH:mm:ss"),
            theme: ticket.theme.description, 
            product_name: ticket.product_name, 
            shop_city: ticket.shop_city,
            description: ticket.description,
            status: statuses[ticket.status.id].name,
            statusId: ticket.status.id,
            is_test: ticket.is_test,
          };
        });
        return {tickets: tickets, count: response.count};
      },
      throws: true,
    }),
    getTicketById: builder.query({
      query: (ticketId) => `milk_alliance/tickets/${ticketId}`,
      transformResponse: (response) => {
        response.status.name = statuses[response.status.id].name;
        const status_changes_list = [{
                                      date: response.call_date, 
                                      status: statuses["1"].name, 
                                      statusId: 1,
                                      username: null
                                    }];
        const status_changes = response.status_changes.map((row) => ({
                                      date: row.changed_at, 
                                      status: statuses[row.status.id].name, 
                                      statusId: row.status.id,
                                      username: row.changed_by.username
                                    }));
        response.status_changes_list = status_changes_list.concat(status_changes);
        return response
      },
      throws: true,
    }),
    addComment: builder.mutation({
      query: ({ticketId, comment:text}) => ({
        url: `milk_alliance/tickets/${ticketId}/comments`,
        method: 'POST',
        body: JSON.stringify({ "text": text }),
      }),
      throws: true,
    }),
    uploadFile: builder.mutation({
      query: ({ ticketId, file }) => {
        const formData = new FormData();
        formData.append('file', file);
        return {
          url: `milk_alliance/tickets/${ticketId}/files/upload`,
          method: 'POST',
          body: formData,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        };
      },
      throws: true,
    }),
    changeStatus: builder.mutation({
      query: ({ ticketId, newStatusId }) => ({
        url: `milk_alliance/tickets/${ticketId}/status`,
        method: 'PATCH',
        body: JSON.stringify({ "status_id": newStatusId }),
      }),
      throws: true,
    }),
    getThemes: builder.query({
      query: () => 'milk_alliance/themes',
      transformResponse: (response) => {
        return response.map((theme) => ({
          value: theme.id,
          label: theme.description,
        }));
      },
      throws: true,
    }),
    getStatuses: builder.query({
      query: () => 'milk_alliance/statuses',
      transformResponse: (response) => {
        return response.map((status) => ({
          id: status.id,
          name: statuses[status.id].name,
        }));
      },
      throws: true,
    }),
  }),
});

export const { 
    useGetTicketsQuery,
    useGetTicketByIdQuery,
    useAddCommentMutation,
    useUploadFileMutation,
    useChangeStatusMutation,
    useGetThemesQuery,
    useGetStatusesQuery,
} = ticketsApi;