import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const BASE_URL = process.env.REACT_APP_API_BASE_URL


export const audioRecordsApi = createApi({
  reducerPath: 'audioRecordsApi',
  baseQuery: fetchBaseQuery({ 
    baseUrl: BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const accessToken = getState().auth.access;
      if (accessToken) {
        headers.set('Authorization', `Bearer ${accessToken}`);
      }
      return headers;
    },
    timeout: 30000,
    onQueryError: (error) => {
      console.error('An error occurred:', error);
    },
  }),
  keepUnusedDataFor: 0,
  endpoints: (builder) => ({
    getAudioRecords: builder.query({
      refetchOnMountOrArgChange: false,
      query: ({record_id, pilot, skill, start_datetime, end_datetime, agent, end_cause, called_number, bc, limit, offset}) => {
        const recordId = record_id ? `&record_id=${record_id}` : '';
        const pilotParam = pilot ? `&pilot=${pilot}` : '';
        const skillParam = skill ? `&skill=${skill}` : '';
        const startDatetime = start_datetime ? `&start_datetime=${start_datetime}` : '';
        const endDatetime = end_datetime ? `&end_datetime=${end_datetime}` : '';
        const agentParam = agent ? `&agent=${agent}` : '';
        const endCause = end_cause ? `&end_cause=${end_cause}` : '';
        const calledNumber = called_number ? `&called_number=${called_number}` : '';
        const accessCode = bc ? `&access_code=${bc}` : '';
        const limitParam = `&limit=${limit}`;
        const offsetParam = `&offset=${offset}`;
        return `audio_records/search?${recordId}${pilotParam}${skillParam}${startDatetime}${endDatetime}${agentParam}${endCause}${calledNumber}${accessCode}${limitParam}${offsetParam}`
      },
      transformResponse: (response) => {
       const resp = {
        records: Object.values(response.records),
        count: response.count,
       }
       resp.records.forEach((record) => {
          record.uniqueId = `${record.ccID}-${record.StartDateTime}`;
          record.details.forEach((detail, index) => {

            detail.PhoneNumber = null;
            detail.talkDuration = detail.EffectiveCallDuration - detail.CALL_ON_HOLD_TIME;
            detail.uniqueId = `${detail.ccID}-${detail.StartDateTime}`;
            
            if (detail) {
              if (index == (record.details.length - 1)) {
                record.a_END_CAUSE = detail.a_END_CAUSE;
                record.e_END_CAUSE_NAME = detail.e_END_CAUSE_NAME;
              };
              if (detail?.CallingNumberName?.includes('IVR')) {
                record.PhoneNumber = detail.NDS;                
              }else{
                record.agentNumber = detail.CallingNumber;
                record.agentName = detail.CallingNumberName;
                record.agentNumName = `${detail.CallingNumber}: ${detail.CallingNumberName}`;

                detail.agentNumber = detail.CallingNumber;
                detail.agentName = detail.CallingNumberName;
                detail.agentNumName = `${detail.CallingNumber}: ${detail.CallingNumberName}`;
              }

              if (detail?.CallTypeName?.includes("PublicNetworkOutgoingCall")) {
                record.PhoneNumber = detail.CallingNumber;
                record.agentNumber = detail.ChargedNumber;
                record.agentName = detail.ChargedNumberName;
                record.agentNumName = `${detail.ChargedNumber}: ${detail.ChargedNumberName}`;

                detail.PhoneNumber = detail.CallingNumber;
                detail.agentNumber = detail.ChargedNumber;
                detail.agentName = detail.ChargedNumberName;
                detail.agentNumName = `${detail.ChargedNumber}: ${detail.ChargedNumberName}`;
                
              };
            };
          });
          record.talkDuration = record.EffectiveCallDuration - record.CALL_ON_HOLD_TIME;
       });
        return resp;
      },
      throws: true,
    }),
  }),
  onError: (error, { dispatch }) => {
    if (error.status === 500) {
      // dispatch(audioRecordsSlice.actions.clearAudioRecords());
    }
  },
});

export const { 
    useGetAudioRecordsQuery,
} = audioRecordsApi;