import React, {useEffect} from 'react'
import { useNavigate } from 'react-router-dom';


const HomePage = () => {
  const navigate = useNavigate();
  useEffect(() => {
    // location.pathname = '/tickets';
    navigate('/tickets');
  }, [])

  return (
    <div></div>
  )
}

export default HomePage