import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useGetTokenMutation } from '../../utils/api/authApi';
import { isLoggedIn } from '../../utils/reducers/authReducer';
import { Form, Input, Button, Spin } from 'antd';
import { LoginOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import logo from './adelina_new.png';


import './LoginPage.css';

const LoginPage = () => {
  const { t } = useTranslation();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const [getToken, { isLoading, isError }] = useGetTokenMutation();
  const [loading, setLoading] = useState(false);
  const loggedIn = useSelector(isLoggedIn);


  const handleSubmit = async (e) => {
    setLoading(true);
    try {
      const reToken = await executeRecaptcha('login');
      await getToken({ username, password, recaptchaToken: reToken }).unwrap();
    } catch (error) {
      console.error('Login failed:', error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="login-container">
        <Spin size="large" className="login-spinner" />
      </div>
    );
  }

  return (
    <div className="login-container">
      <div className="logo-container">
        <img src={logo} alt="Logo" className="logo-login" />
        <h1 className="logo-text">{t("Issue Management System")}</h1>
      </div>
      <Form className="login-form" onFinish={handleSubmit}>
        <Form.Item className="login-form-item">
          <Input
            placeholder={t("Username")}
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
            autoComplete="username"
          />
        </Form.Item>
        <Form.Item className="login-form-item">
          <Input
            placeholder={t("Password")}
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            autoComplete="current-password"
          />
        </Form.Item>
        <Form.Item>
          <Button className="login-form-button" type="primary" htmlType="submit" size="middle" icon={<LoginOutlined />}>
            {t("Log in")}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default LoginPage;
