// utils/useRefreshTokenTimer.js
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isLoggedIn, logout } from '../reducers/authReducer';
import { useRefreshTokenMutation } from '../api/authApi';

export const useRefreshTokenTimer = () => {
  const loggedIn = useSelector(isLoggedIn);
  const dispatch = useDispatch();
  const [refreshToken] = useRefreshTokenMutation();
  const accessTokenExpirationTime = useSelector((state) => state.auth.expiration);
  const accessTTL = useSelector((state) => state.auth.access_ttl);
  const token = useSelector((state) => state.auth.access);

  const [delay, setDelay] = useState(null);
  const predelay = 10;
  // const predelay = 295;

  useEffect(() => {
    if (loggedIn) {
      const currentTime = Date.now();
      if (accessTokenExpirationTime && currentTime < accessTokenExpirationTime) {
        setDelay(accessTokenExpirationTime - currentTime - (predelay * 1000));
      } else {
        setDelay((accessTTL - predelay) * 1000);
      }
    }
  }, [loggedIn, accessTokenExpirationTime, accessTTL]);

  useEffect(() => {
    let timer;

    if (loggedIn && delay) {
      timer = setTimeout(() => {
        refreshToken().catch(() => {
          dispatch(logout());
        });
      }, delay);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [loggedIn, refreshToken, dispatch, delay, token]);
};
