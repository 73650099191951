// PermissionWrapper.js
import React from 'react';
import { useSelector } from 'react-redux';

const PermissionWrapper = ({ requiredPermission, children }) => {
  const permissions = useSelector((state) => state.auth.permissions);

  if (!permissions.includes(requiredPermission)) {
    return null;
  }

  return <>{children}</>;
};

export default PermissionWrapper;
