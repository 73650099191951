import { createSlice } from '@reduxjs/toolkit';
import { userApi } from '../api/userApi';
import { loadState, saveState } from '../localStorage';


const initialState = loadState('auth') || {};

const userSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {  },
  extraReducers: (builder) => {
    builder
      .addMatcher(userApi.endpoints.getUserInfo.matchFulfilled, (state, action) => {
        const prevState = loadState('auth');
        prevState.groups = action.payload.groups;
        prevState.permissions = action.payload.permissions;
        saveState('auth', prevState);
    })
  },
});

export const { getUserInfo } = userSlice.actions;

export default userSlice.reducer;
