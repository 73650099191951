import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const BASE_URL = process.env.REACT_APP_API_BASE_URL

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: fetchBaseQuery({ 
    baseUrl: BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const accessToken = getState().auth.access;
      if (accessToken) {
        headers.set('Authorization', `Bearer ${accessToken}`);
      }
      return headers;
    },
    timeout: 5000,
    onQueryError: (error) => {
      console.error('An error occurred:', error);
    },
  }),
  endpoints: (builder) => ({
    getUserInfo: builder.query({
      query: () => {
        return `milk_alliance/me`
      },
      transformResponse: (response) => {
        return response;
      },
      throws: true,
    }),
  }),
});

export const { 
    useGetUserInfoQuery,
} = userApi;