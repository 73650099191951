// useLanguage.js
import { useSelector, useDispatch } from 'react-redux';
import { changeLanguage } from '../reducers/authReducer';
import { saveState, loadState } from '../localStorage';

export const useLanguage = () => {
  const dispatch = useDispatch();
  const language = useSelector((state) => state.auth.language);

  const updateLanguage = (newLanguage) => {
    dispatch(changeLanguage(newLanguage));
    const state = loadState('auth');
    state.language = newLanguage;
    saveState('auth', state);
  };

  return [language, updateLanguage];
};
