import { createSlice } from '@reduxjs/toolkit';
import { authApi } from '../api/authApi';
import { saveState, removeState } from '../localStorage';

const initialState = {
  username: null,
  access: null,
  refresh: null,
  access_ttl: null,
  expiration: null,
  user: null,
  language: 'ua',
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: (state) => {
      Object.assign(state, initialState);
      removeState('auth');
      removeState('ticketsFilters');

    },
    changeLanguage: (state, action) => {
      state.language = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      authApi.endpoints.getToken.matchFulfilled,
      (state, { payload }) => {
        state.username = payload.username;
        state.groups = payload.groups;
        state.permissions = payload.permissions;
        state.access = payload.access;
        state.refresh = payload.refresh;
        state.access_ttl = payload.access_ttl;
        state.expiration = Date.now() + payload.access_ttl * 1000;
        state.language = payload.language || 'ua';
        saveState('auth', state);
      }
    );
    builder.addMatcher(
      authApi.endpoints.refreshToken.matchFulfilled,
      (state, { payload }) => {
        state.access = payload.access;
        state.refresh = payload.refresh;
        state.access_ttl = payload.access_ttl;
        state.expiration = Date.now() + payload.access_ttl * 1000;
        saveState('auth', state);
      }
    );
  },
});

export const { logout, changeLanguage } = authSlice.actions;

export default authSlice.reducer;

export const isLoggedIn = (state) => {
  const authState = state.auth;
  if (authState.access && authState.expiration) {
    return new Date().getTime() < authState.expiration;
  }
  return false;
};
