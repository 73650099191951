import React from 'react'
import { useNavigate } from 'react-router-dom';
import { Result, Button } from 'antd';
import { useTranslation } from 'react-i18next';


const Page403 = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    return (
        <Result
          status="403"
          title="403"
          subTitle={t("Sorry, you are not authorized to access this page.")}
          extra={<Button type="primary" onClick={()=>{navigate('/')}}>{t("Return")}</Button>}
  />
      )
}

export default Page403