import React, { useState, useEffect } from "react";
import { Table, Spin, Alert, Button, Tag, Tooltip, DatePicker, Input, Select, Space, Checkbox } from "antd";
import { EditOutlined, SyncOutlined, IssuesCloseOutlined, PhoneOutlined, ClearOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import TicketDetailsPage from "./TicketDetailsPage";
import dayjs from "dayjs";
import weekOfYear from "dayjs/plugin/weekOfYear";
import isoWeek from "dayjs/plugin/isoWeek";

import { useGetTicketsQuery, useGetThemesQuery } from "../../utils/api/ticketsApi";
import { useGroup } from "../../utils/hooks/usePermission";
import "./TicketsPage.css";
import { loadState, saveState } from "../../utils/localStorage";

import { useTranslation } from "react-i18next";


dayjs.extend(weekOfYear);
dayjs.extend(isoWeek);

const { RangePicker } = DatePicker;
const { Option } = Select;

const TicketsPage = () => {
  const { t } = useTranslation();
  const isAdmin = useGroup('milk_alliance_admin');
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [tickets, setTickets] = useState([]);
  const [ticketsCount, setTicketsCount] = useState();

  const [dateRange, setDateRange] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [theme, setTheme] = useState(null);
  const [status, setStatus] = useState(null);
  const [showTestRecords, setShowTestRecords] = useState(0);

  const [isFiltered, setIsfiltered] = useState(null);
  const savedPage = JSON.parse(localStorage.getItem("ticketsPage")) || 1;
  const savedPageSize = JSON.parse(localStorage.getItem("ticketsPageSize")) || 10;

  const [page, setPage] = useState(savedPage);
  const [pageSize, setPageSize] = useState(savedPageSize);

  const { data: themesQuery } = useGetThemesQuery();

  const { data, isFetching: isLoadingTickets, error } = useGetTicketsQuery({
    offset: pageSize,
    limit: page,
    call_date_start:
      dateRange && dateRange.length
        ? dayjs(dateRange[0].toISOString()).format("YYYY-MM-DD")
        : null,
    call_date_end:
      dateRange && dateRange.length
        ? dayjs(dateRange[1].toISOString()).format("YYYY-MM-DD")
        : null,
    phone: phoneNumber,
    theme: theme,
    status: status,
    is_test: showTestRecords ? 1 : 0,
  },
  );


  const handleClear = () => {
    setDateRange(null);
    setPhoneNumber(null);
    setTheme(null);
    setStatus(null);
  };

  const statuses = {
    1: { name: t("New"), color: "red", icon: <ExclamationCircleOutlined /> },
    2: { name: t("In progress"), color: "blue", icon: <SyncOutlined /> },
    3: { name: t("Closed"), color: "green", icon: <IssuesCloseOutlined /> },
  }

  useEffect(() => {
    if (data) {
      setTickets(data.tickets);
      setTicketsCount(data.count);
    }
  }, [data]);

  useEffect(() => {
    const savedFilters = loadState("ticketsFilters") || {};
      // JSON.parse(localStorage.getItem("ticketsFilters")) || {};

    if (savedFilters.dateRange) {
      setDateRange([
        dayjs(savedFilters.dateRange[0]),
        dayjs(savedFilters.dateRange[1]),
      ]);
    }
    if (savedFilters.phoneNumber) setPhoneNumber(savedFilters.phoneNumber);
    if (savedFilters.theme) setTheme(savedFilters.theme);
    if (savedFilters.status) setStatus(savedFilters.status);
    if (savedFilters.showTestRecords) setShowTestRecords(savedFilters.showTestRecords);
  }, []);

  useEffect(() => {
    setIsfiltered((dateRange?.[0] || dateRange?.[1] || phoneNumber || theme || status)>0)
  }, [dateRange, phoneNumber, theme, status]);

  useEffect(() => {
    const filters = {
      dateRange:
        dateRange && dateRange.length
          ? [dateRange[0].toISOString(), dateRange[1].toISOString()]
          : null,
      phoneNumber,
      theme,
      status,
      showTestRecords,
    };
    saveState("ticketsFilters", filters);
    // localStorage.setItem("ticketsFilters", JSON.stringify(filters));
  }, [dateRange, phoneNumber, theme, status, showTestRecords]);

  const onDateRangeChange = (values) => {
    if (values && values.length) {
      setDateRange(values);
    } else {
      setDateRange(null);
    }
  };

  const handleIdClick = (ticket) => {
    setSelectedTicket(ticket);
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setSelectedTicket(null);
    setIsModalVisible(false);
  };

  const onStatusChange = (ticketId, newStatus) => {
    setTickets(
      tickets.map((ticket) => {
        if (ticket.id === ticketId) {
          return {
            ...ticket,
            status: statuses[newStatus].name,
            // status: statuses[newStatus].name,
            statusId: newStatus,
          };
        } else {
          return ticket;
        }
      })
    );
  };

  const handlePageChange = (newPage, newPageSize) => {
    setPage(newPage);
    setPageSize(newPageSize);
  };

  const getPresetRanges = () => {
    const now = dayjs();
    const today = now.clone().startOf('day');
    const yesterday = now.clone().subtract(1, 'day').startOf('day');
    const endOfYesterday = now.clone().subtract(1, 'day').endOf('day');
    const startOfSevenDays = now.clone().subtract(6, 'day').startOf('day');
    const endOfSevenDays = now.clone().endOf('day');
    const startOfWeek = now.clone().startOf('isoWeek');
    const endOfWeek = now.clone().endOf('isoWeek');
    const startOfMonth = now.clone().startOf('month');
    const endOfMonth = now.clone().endOf('month');
    const startOfPrevWeek = now.clone().subtract(1, 'week').startOf('isoWeek');
    const endOfPrevWeek = now.clone().subtract(1, 'week').endOf('isoWeek');
    const startOfPrevMonth = now.clone().subtract(1, 'month').startOf('month');
    const endOfPrevMonth = now.clone().subtract(1, 'month').endOf('month');
  
    return [
      { label: t('Today'), value: [today, now] },
      { label: t('Yesterday'), value: [yesterday, endOfYesterday] },
      { label: t('Yesterday+today'), value: [yesterday, now] },
      { label: t('Last 7 days'), value: [startOfSevenDays, endOfSevenDays] },
      { label: t('Current week'), value: [startOfWeek, endOfWeek] },
      { label: t('Current month'), value: [startOfMonth, endOfMonth] },
      { label: t('Previous week'), value: [startOfPrevWeek, endOfPrevWeek] },
      { label: t('Previous month'), value: [startOfPrevMonth, endOfPrevMonth] },
    ];
  };
  

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: "8%",
      render: (text, record) => {
        return (
          <Button
            type={!record.is_test ? "dashed" : "default"}
            style={{ width: "100px", textAlign: "center" }}
            icon={<EditOutlined />}
            onClick={() => handleIdClick(record)}
          >
            {text}
          </Button>
        );
      },
    },
    {
      title: t("Call date"),
      dataIndex: "call_date",
      key: "call_date",
      width: "10%",
    },
    {
      title: t("Theme"),
      dataIndex: "theme",
      key: "theme",
      ellipsis: { showTitle: false },
      width: "15%",
    },
    {
      title: t("Product name"),
      dataIndex: "product_name",
      key: "product_name",
      width: "17%",
      ellipsis: { showTitle: false },
    },
    {
      title: t("City"),
      dataIndex: "shop_city",
      key: "shop_city",
      width: "10%",
      ellipsis: { showTitle: false },
    },
    {
      title: t("Description"),
      dataIndex: "description",
      key: "description",
      width: "30%",
      ellipsis: { showTitle: false },
      render: (description) => (
        <Tooltip
          placement="left"
          title={description}
          autoAdjustOverflow={true}
        >
          {description}
        </Tooltip>
      ),
    },
    {
      title: t("Status"),
      dataIndex: "status",
      key: "status",
      width: "10%",
      render: (text, record) => (
        <Tag
          onClick={() => handleIdClick(record)}
          style={{ width: "100px", textAlign: "center", cursor: "pointer",}}
          color={statuses[record.statusId].color}
          icon={statuses[record.statusId].icon}
        >
          {t(`${text}`)}
        </Tag>
      ),
    },
  ];

  // const getRowClassName = (record) => {
  //   return `table-row-${record.statusId}`;
  // };

  if (!data) {
    return (
      <div className="tickets-page-content">
        <div className="tickets-page-spinner">
          <Spin size="large" />
        </div>
      </div>
      );
  }

  if (error) {
    return (
      <Alert
        message={t("Error when loading tickets")}
        description={error.message}
        type="error"
        showIcon
      />
    );
  }

  return (
    <div className="tickets-page-content">
      <Space direction="horizontal" size={30} className="filters-space">
      {isAdmin ?
      <div style={{width:50}}>
          <div className="show-test-record">
            <Tooltip
              title={t("Show test records. Checkbox visible only for admin group.")}>
              <Checkbox
                  className="centered-checkbox"
                  checked={showTestRecords}
                  defaultChecked={isAdmin}
                  onChange={() => setShowTestRecords(!showTestRecords)}
                >
                  <span style={{fontSize:12}}>{t("Test")}</span>
              </Checkbox>
              </Tooltip>
          </div>
        </div>
        : null}
        <RangePicker
          value={dateRange}
          onChange={onDateRangeChange}
          style={{ marginBottom: 16, minWidth: 300 }}
          presets={getPresetRanges()}
        />
        <Input
          value={phoneNumber}
          allowClear
          onChange={(e) => setPhoneNumber(e.target.value)}
          placeholder={t("Phone number")}
          prefix={<PhoneOutlined />}
          style={{ marginBottom: 16 }}
        />
        <Select
          value={theme}
          onChange={setTheme}
          placeholder={t("Theme")}
          style={{ minWidth: 250, marginBottom: 16 }}
          allowClear
          options={themesQuery}
        />
        <Select
          value={status}
          onChange={setStatus}
          placeholder={t("Status")}
          style={{ minWidth: 150, marginBottom: 16 }}
          allowClear
        >
          <Option value="1">{t("New")}</Option>
          <Option value="2">{t("In progress")}</Option>
          <Option value="3">{t("Closed")}</Option>
        </Select>
        <div style={{width:30}}>
          <div hidden={!isFiltered} className="clear-filters-button">
            <Tooltip
              title={t("Clear all filters")}>
              <Button
                size="middle"
                type="dashed"
                icon={<ClearOutlined />}
                onClick={() => {handleClear()}}
              />
              </Tooltip>
          </div>
        </div>
      </Space>
      <Table
        size="small"
        loading={isLoadingTickets}
        // rowClassName={getRowClassName}
        pagination={{
          position: ["none", "bottomCenter"],
          pageSize: pageSize,
          showSizeChanger: true,
          hideOnSinglePage: false,
          onShowSizeChange: handlePageChange,
          onChange: handlePageChange,
          pageSizeOptions: [10, 20, 50],
          total: ticketsCount,
          // showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`
        }}
        columns={columns}
        dataSource={tickets}
        rowKey="id"
      />
      {selectedTicket && (
        <TicketDetailsPage
          ticketId={selectedTicket.id}
          visible={isModalVisible}
          onCancel={handleModalClose}
          onStatusChange={onStatusChange}
        />
      )}
    </div>
  );
};

export default TicketsPage;
