import React from 'react'
import { useParams } from 'react-router-dom';
import TicketDetailsPage from './TicketDetailsPage';

const TicketPage = () => {
    const params = useParams();
  return (
    <div className="tickets-page-content">
        <TicketDetailsPage
          ticketId={params.ticketId}
          visible={true}
        />
    </div>
  )
}

export default TicketPage