import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)

export function secondsToTimeFormat(seconds) {
  const date = new Date(0);
  date.setUTCHours(0, 0, seconds || 0, 0);

  // Format the Date instance using dayjs
  const time = dayjs.utc(date).format('HH:mm:ss');

  return time;
}
