import React from 'react';
import { Button } from 'antd';
import { useDispatch } from 'react-redux';
import { logout } from '../../utils/reducers/authReducer';
import { useNavigate } from 'react-router-dom';
import { LogoutOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import './LogoutButton.css';

const LogoutButton = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      dispatch(logout());
      navigate('/');
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  return (
    <Button
      className="logout-button"
      danger
      type="primary"
      onClick={handleLogout}
      size="middle"
      icon={<LogoutOutlined />}
    >
      {t("Log out")}
    </Button>
  );
};

export default LogoutButton;
