import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import React from 'react';
import { useSelector } from "react-redux";
import { isLoggedIn } from './app/utils/reducers/authReducer';
import { LoginPage, MainLayout } from './app/components';
import { useRefreshTokenTimer } from './app/utils/hooks/useRefreshTokenTimer';

function App() {
  const loggedIn = useSelector(isLoggedIn);
  useRefreshTokenTimer();
  return loggedIn ? <MainLayout /> : <GoogleReCaptchaProvider reCaptchaKey={"6LeC_QQmAAAAALnc7D2mmzir15m-lbin3if_84Bl"}><LoginPage /></GoogleReCaptchaProvider>;
}

export default App;
