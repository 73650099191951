import { Button, Row, Col, Space, Slider, Tooltip } from 'antd';
import { PlayCircleOutlined, PauseCircleOutlined, DownloadOutlined, LoadingOutlined } from '@ant-design/icons';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { WaveSurfer, WaveForm } from 'wavesurfer-react';
import TimelinePlugin from 'wavesurfer.js/dist/plugin/wavesurfer.timeline.min';


const AudioPlayer = ({ id, audioUrl, playPause, playAfterLoad, onIsPlayingChange, onCurrentDurationChange, onLoadingChange, token, onError }) => {
    const [isPlaying, setIsPlaying] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [currentTime, setCurrentTime] = useState("00:00:00");
    const [volume, setVolume] = useState(60);
    const waveformId = `waveform-${id}`;
    const timelineId = `timeline-${id}`;
    const [playButtonGhost, setPlayButtonGhost] = useState(true);
    const tokenRef = useRef(token);
    const playAfterLoadRef = useRef(playAfterLoad);

    useEffect(() => {
        tokenRef.current = token;
    }, [token]);

    useEffect(() => {
        playAfterLoadRef.current = playAfterLoad;
    }, [playAfterLoad]);

    const params = useMemo(() => {
            return {
                splitChannels: true,
                backend: 'WebAudio',
                xhr: { requestHeaders: [tokenRef.current ? { key: "authorization",value: "Bearer " + tokenRef.current} : {}],},
                plugins: [
                    {
                        plugin: TimelinePlugin,
                        options: {
                            container: '#' + timelineId,
                        }
                    },
                    
                ],
            }
        }, [tokenRef.current]);

    const wavesurferRef = useRef(null);

    const handleMount = useCallback((waveSurfer) => {
        wavesurferRef.current = waveSurfer;
        if (wavesurferRef.current) {
            if (window) {
                window.surferidze = wavesurferRef.current;
            };
        };

        if (wavesurferRef.current) {

            wavesurferRef.current.on('seek', (data) => {
                let calculatedTime = new Date(null);
                calculatedTime.setSeconds( parseInt(wavesurferRef.current.getCurrentTime()) );
                let newTime = calculatedTime.toISOString().substr(11, 8);
                setCurrentTime(newTime);
                onCurrentDurationChange(parseInt(wavesurferRef.current.getCurrentTime()));
            });

            wavesurferRef.current.on('loading', (data) => {     
                onLoadingChange(data);
                if(data === 100){
                    setIsLoading(false);
                } else {
                    setIsLoading(true);
                }
            });

            wavesurferRef.current.on('ready', () => {     
                if(playAfterLoadRef.current){  
                    wavesurferRef.current.play();  
                    setIsPlaying(true)
                }
            });

            wavesurferRef.current.on('play', () => {
                setIsPlaying(true);
            });

            wavesurferRef.current.on('pause', () => {
                setIsPlaying(false);
            });

            wavesurferRef.current.on('audioprocess', (data) => {
                let calculatedTime = new Date(null);
                calculatedTime.setSeconds( parseInt(data) ); //setting value in seconds
                let newTime = calculatedTime.toISOString().substr(11, 8);
                setCurrentTime(newTime);
                onCurrentDurationChange(parseInt(data));
            });

            wavesurferRef.current.on('finish', (data) => {
                setIsPlaying(false);
                wavesurferRef.current.seekTo(0);
            });

            wavesurferRef.current.on('error', (data) => {
                // console.log(data);
                onError(data);
                
            });
        };
    }, [audioUrl]);


    const handleKeyDown = useCallback((event) => {
        const tagName = event.target.tagName.toLowerCase();
        if (tagName === 'input' || tagName === 'textarea') {
            return;
        }    

        if (event.code === 'Space') {
            event.preventDefault();
            wavesurferRef.current.playPause();
        };
        
        if (event.code === 'ArrowLeft') {
            event.preventDefault();
            wavesurferRef.current.skipBackward(5);
        };

        if (event.code === 'ArrowRight') {
            event.preventDefault();
            wavesurferRef.current.skipForward(5);
        };

        if ((event.metaKey || event.ctrlKey) && event.code === 'ArrowLeft') {
            event.preventDefault();
            wavesurferRef.current.seekTo(0)
        };

        if ((event.metaKey || event.ctrlKey) && event.code === 'ArrowRight') {
            event.preventDefault();
            wavesurferRef.current.seekTo(1)
        };

        if (event.code === 'KeyM') {
            event.preventDefault();
            if(wavesurferRef.current.getVolume() > 0){
                setVolume(0);
                wavesurferRef.current.setVolume(0);
            } else {
                setVolume(60);
                wavesurferRef.current.setVolume(volume/100);
            };
        };
    }, [audioUrl]);

    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleKeyDown]);

    const handleUnmount = useCallback(() => {
        if (wavesurferRef.current) {
            wavesurferRef.current.unAll();
            wavesurferRef.current.destroy();
            wavesurferRef.current = null;
        }
    }, []);

    useEffect(() => {
        if (wavesurferRef.current) {
            wavesurferRef.current.load(audioUrl);
        }
      }, [audioUrl]);

    useEffect(() => {
        if (typeof onIsPlayingChange === 'function') {
            onIsPlayingChange(isPlaying);
            setPlayButtonGhost(!isPlaying);
        }
    }, [isPlaying]);

    useEffect(() => {
        if(playPause){
            wavesurferRef.current.play();
        } else {
            wavesurferRef.current.pause();
        }
    }, [playPause]);

    const handlePlayPause = () => {
        if (wavesurferRef.current) {
            wavesurferRef.current.playPause();
        }
    };
    
    const handleVolume = (volume) => {
        if (wavesurferRef.current) {
            wavesurferRef.current.setVolume(volume/100);
            setVolume(volume);
        }
    };

    return (
        <Row>
            <Col span={2} style={{justifyContent:"center", display:"flex", flexDirection:"row"}}>
                <Space size={5} direction="vertical">
                <Space size={5} direction="horizontal">
                    <Button target="_blank" href={audioUrl} type="primary" ghost ><DownloadOutlined/></Button>
                    <Button type="primary" ghost={playButtonGhost} onClick={handlePlayPause}>{isLoading ? <LoadingOutlined/> : !isPlaying ? <PlayCircleOutlined/> : <PauseCircleOutlined/>}</Button> 
                </Space>
                <Space size={1} direction="vertical" style={{minWidth:"100%"}}>
                    <div style={{textAlign:"center"}}>{currentTime}</div>
                    <Slider value={volume} defaultValue={0.6} max={100} onChange={handleVolume} placement="bottom" formatter={<Tooltip title={`Volume ${volume}`} >{`Volume ${volume}`}</Tooltip>}/>
                </Space>
                </Space>
            </Col>
            <Col span={22} style={{minWidth:"88%"}}>
                <WaveSurfer 
                    key={`wskey_${audioUrl}`}
                    backend={params.backend}
                    waveColor={'#e8b58b'}
                    progressColor={'#727cad'}
                    xhr={params.xhr}
                    plugins={params.plugins} 
                    onMount={handleMount} 
                    onUnmount={handleUnmount}
                    height={50}>            
                    <WaveForm id={waveformId}/>
                    <div id={timelineId}></div>
                </WaveSurfer>
            </Col>
        </Row>
    );
}


export default AudioPlayer;