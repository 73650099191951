import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { Table, Button, Tooltip, DatePicker, Input, Select, Space, Row, Form } from "antd";
import { DownloadOutlined, SearchOutlined, ClearOutlined, PhoneOutlined, PauseCircleOutlined, PlayCircleOutlined, UserOutlined } from "@ant-design/icons";
import { useGetAudioRecordsQuery } from '../../utils/api/audioRecordsApi';
import { resetAudioRecords } from '../../utils/reducers/audioRecordsReducer';
import dayjs from 'dayjs';
import weekOfYear from "dayjs/plugin/weekOfYear";
import isoWeek from "dayjs/plugin/isoWeek";
import { secondsToTimeFormat as stt } from '../../utils/functions/dateTime';
import { useDispatch } from 'react-redux';
import { useTranslation } from "react-i18next";
import store from '../../utils/store';
import "./AudioRecordsPage.css";
import { AudioPlayer } from '../AudioPlayer'

import advancedFormat from 'dayjs/plugin/advancedFormat'; // this is needed to use "HH:mm" format
import customParseFormat from 'dayjs/plugin/customParseFormat'; // this is needed to parse custom format


dayjs.extend(advancedFormat);
dayjs.extend(customParseFormat);
dayjs.extend(weekOfYear);
dayjs.extend(isoWeek);


const MAX_RANGE_DAYS = 7;
const { RangePicker } = DatePicker;
const BASE_URL = process.env.REACT_APP_BASE_URL
const DEFAULT_AUDIO_URL = process.env.REACT_APP_DEFAULT_AUDIO_URL


const AudioRecordsPage = () => {
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const [audioUrl, setAudioUrl] = useState(DEFAULT_AUDIO_URL);
  // const [audioUrl, setAudioUrl] = useState(`http://localhost:3001/audio?url=${DEFAULT_AUDIO_URL}&token=${token}`);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isPlayingId, setIsPlayingId] = useState(null);
  const [currentDuration, setCurrentDuration] = useState(0);
  const [activeRow, setActiveRow] = useState(null);
  const [playAfterLoad, setPlayAfterLoad] = useState(false);
  const [isLoadingAudio, setIsLoadingAudio] = useState(false);
  const [loadAudioPercent, setLoadAudioPercent] = useState(0);
  const [isLoadingError, setIsLoadingError] = useState(false);

  const [shouldFetch, setShouldFetch] = useState(true);
  const [isFiltered, setIsFiltered] = useState(false);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(10);
  const [dateRange, setDateRange] = useState([dayjs().subtract(1, 'day').startOf('day'), dayjs().subtract(0, 'day').endOf('day')]); //[dayjs().startOf('day'), dayjs().endOf('day')]
  const [dateRangeValue, setDateRangeValue] = useState();
  const [phoneNumber, setPhoneNumber] = useState();
  const [callType, setCallType] = useState("inbound");
  const [agentNumber, setAgentNumber] = useState(null);
  
  const callTypes2 = {
    "inbound": {v:"75923"},
    "outbound_transfer": {v:"286"},
    "outbound_callback": {v:"305"},
  }

  const [pilot, setPilot] = useState(callType==="inbound" ? callTypes2[callType]?.v : null);
  const [bc, setBc] = useState(callType==="outbound_transfer" && callType==="outbound_callback" ? callTypes2[callType]?.v : null);


  const callTypes = {
    "inbound": {f:setPilot, v:"75923", cf:setBc},
    "outbound_transfer": {f:setBc, v:"286", cf:setPilot},
    "outbound_callback": {f:setBc, v:"305", cf:setPilot},
  }

  const searchParamsValues = 
    {
      offset: offset,
      limit: limit,
      start_datetime:
        dateRange && dateRange.length
          ? dayjs(dateRange[0]?.toISOString()).format("YYYY-MM-DD HH:mm")
          : null,
      end_datetime:
        dateRange && dateRange.length
          ? dayjs(dateRange[1]?.toISOString()).format("YYYY-MM-DD HH:mm")
          : null,
      called_number: phoneNumber,
      pilot: pilot,
      bc: bc,
      agent: agentNumber,
    }
  const [searchParams, setSearchParams] = useState(searchParamsValues);


    const { data, isFetching: isLoadingRecords, error, refetch, isSuccess } = useGetAudioRecordsQuery(searchParams);

    useEffect(() => {
      setIsPlaying(false);
      setCurrentDuration(0);
    },[])

    useEffect(() => {
      callTypes[callType]?.f(callTypes[callType]?.v);
      callTypes[callType]?.cf(null);
    },[callType])


    useEffect(() => {
      dispatch(resetAudioRecords());
      setIsFiltered((dateRange?.[0] || dateRange?.[1] || !!phoneNumber || !!callType || !!agentNumber)>0)
    }, [dateRange, phoneNumber, callType, agentNumber]);

    const handleFiltersClear = () => {
      dispatch(resetAudioRecords());
      setDateRange(null);
      setPhoneNumber(null);
      setCallType(null);
      setAgentNumber(null);
      setBc(null);
      // setAudioUrl(`http://localhost:3001/audio?url=${DEFAULT_AUDIO_URL}&token=${tokenRef.current}`);
      setAudioUrl(DEFAULT_AUDIO_URL);
      setActiveRow(null);
      setIsPlaying(false);
      setIsFiltered(false);
    };

    const handleSearch = () => {
      setSearchParams(searchParamsValues);
      // setAudioUrl(`http://localhost:3001/audio?url=${DEFAULT_AUDIO_URL}&token=${tokenRef.current}`);
      setAudioUrl(DEFAULT_AUDIO_URL);
      setActiveRow(null);
      setIsPlaying(false);
      if (isFiltered) {
        dispatch(resetAudioRecords());
        setOffset(0);
        refetch();
      }
    };

    //??????????????????????????????????????????
    const handlePageChange = (page, limit) => {
      setOffset(page);
      searchParamsValues.offset = (page-1)*limit;
      setLimit(limit);
      searchParamsValues.limit = limit;
      setSearchParams(searchParamsValues);
      // setAudioUrl(`http://localhost:3001/audio?url=${DEFAULT_AUDIO_URL}&token=${tokenRef.current}`);
      setAudioUrl(DEFAULT_AUDIO_URL);
      setActiveRow(null);
      setIsPlaying(false);
      if (isFiltered) {
        dispatch(resetAudioRecords());
        refetch();
      }
    };

    const getPresetRanges = () => {
      const now = dayjs();
      const today = now.clone().startOf('day');
      const yesterday = now.clone().subtract(1, 'day').startOf('day');
      const endOfYesterday = now.clone().subtract(1, 'day').endOf('day');
      const startOfSevenDays = now.clone().subtract(6, 'day').startOf('day');
      const endOfSevenDays = now.clone().endOf('day');
      const startOfWeek = now.clone().startOf('isoWeek');
      const endOfWeek = now.clone().endOf('isoWeek');
      const startOfMonth = now.clone().startOf('month');
      const endOfMonth = now.clone().endOf('month');
      const startOfPrevWeek = now.clone().subtract(1, 'week').startOf('isoWeek');
      const endOfPrevWeek = now.clone().subtract(1, 'week').endOf('isoWeek');
      const startOfPrevMonth = now.clone().subtract(1, 'month').startOf('month');
      const endOfPrevMonth = now.clone().subtract(1, 'month').endOf('month');
    
      return [
        { label: t('Today'), value: [today, now] },
        { label: t('Yesterday'), value: [yesterday, endOfYesterday] },
        { label: t('Yesterday+today'), value: [yesterday, now] },
        { label: t('Last 7 days'), value: [startOfSevenDays, endOfSevenDays] },
        { label: t('Current week'), value: [startOfWeek, endOfWeek] },
        { label: t('Previous week'), value: [startOfPrevWeek, endOfPrevWeek] },
      ];
    };

    const disabledDate = (current) => {
      if (!dateRange) {
        return false;
      }
      const tooLate = dateRange[0] && current.diff(dateRange[0], 'days') >= MAX_RANGE_DAYS;
      const tooEarly = dateRange[1] && dateRange[1].diff(current, 'days') >= MAX_RANGE_DAYS;
      return !!tooEarly || !!tooLate;
    };

    const columnsInner = [
      { title: t("Start"), dataIndex: "StartDateTime", key: "StartDateTime", width: 30, fixed: "left", render: (text) => dayjs(text).format("DD.MM.YYYY HH:mm:ss") },
      { title: t("End"), dataIndex: "EndDateTime", key: "EndDateTime", width: 30, fixed: "left", render: (text) => dayjs(text).format("DD.MM.YYYY HH:mm:ss") },
      { title: t("Phone number"), dataIndex: "PhoneNumber", key: "PhoneNumber", width: 30, fixed: "left" },
      { title: t("Agent"), dataIndex: "agentNumName", key: "agentNumName", width: 30, fixed: "left" },
      { title: t("Wait"), dataIndex: "WaitingDuration", key: "WaitingDuration", width: 30, fixed: "left", render: (text) => stt(text) },
      { title: t("Talk"), dataIndex: "talkDuration", key: "talkDuration", width: 30, fixed: "left", render: (text) => stt(text) },
      { title: t("Hold"), dataIndex: "CALL_ON_HOLD_TIME", key: "CALL_ON_HOLD_TIME", width: 30, fixed: "left", render: (text) => stt(text) },
      { title: t("End reason"), dataIndex: "a_END_CAUSE", key: "a_END_CAUSE", width: 30, fixed: "left",
        render: (text, record) => {
          return <Tooltip title={t(record.e_END_CAUSE_NAME)}>{t(record.e_END_CAUSE_NAME)}</Tooltip>
        }},
    ];

    const columnsMain = useMemo(() => [
      { title: "", dataIndex: "url", key: "url", fixed: "left", width: 10,
        className: "audio-records-column-player",
        render:  (text, record) => { 
          const currentUrl = `${BASE_URL}${record.url}`;
          if(record.url && (record.url.slice(-4) === ".mp3" || record.url.slice(-4) === ".wav")) {
            return (<Button 
                    disabled={isLoadingAudio && currentUrl === audioUrl}
                    ghost={!(isPlaying && currentUrl === audioUrl)} 
                    type="primary"
                    danger={isLoadingError && currentUrl === audioUrl}
                    // icon={isLoadingAudio && currentUrl === audioUrl ? <LoadingOutlined/> : isPlaying && currentUrl === audioUrl ? <PauseCircleOutlined/> : <PlayCircleOutlined/>} 
                    onClick={()=>{if(currentUrl.slice(-4) === ".mp3" || currentUrl.slice(-4) === ".wav"){ handleClick(text, record)}}}
                    icon={currentUrl === audioUrl ? isLoadingAudio ? <DownloadOutlined /> : isPlaying ? <PauseCircleOutlined/> : <PlayCircleOutlined/> : <PlayCircleOutlined/>}
                    /> )
            } else {return null}
        }
      },
      { title: t("ID"), dataIndex: "ID", key: "ID", width: 20, fixed: "left"},
      { title: t("Start call"), dataIndex: "StartDateTime", key: "StartDateTime", width: 30, fixed: "left", render: (text) => dayjs(text).format("DD.MM.YYYY HH:mm:ss") },
      { title: t("End call"), dataIndex: "EndDateTime", key: "EndDateTime", width: 30, fixed: "left", render: (text) => dayjs(text).format("DD.MM.YYYY HH:mm:ss") },
      { title: t("Phone number"), dataIndex: "PhoneNumber", key: "PhoneNumber", width: 30, fixed: "left" },
      // { title: "Pilot", dataIndex: "PILOT_STAT", key: "PILOT_STAT", width: 30, fixed: "left" },
      // { title: "AccessCode", dataIndex: "AccessCode", key: "AccessCode", width: 30, fixed: "left" },
      { title: t("Agent"), dataIndex: "agentNumName", key: "agentNumName", width: 30, fixed: "left" },
      { title: t("Duration"), dataIndex: "EffectiveCallDuration", key: "EffectiveCallDuration", width: 30, fixed: "left", 
            render: (text, record) => {
              return (
              <Tooltip title={
                <span>
                  {`${t("Wait")}: ${stt(record.WaitingDuration)}`}<br/>
                  {`${t("Talk")}: ${stt(record.talkDuration)}`}<br/>
                  {`${t("Hold")}: ${stt(record.CALL_ON_HOLD_TIME)}`}
                </span>
                }>
                {`${stt(record.EffectiveCallDuration)} (${record.EffectiveCallDuration} ${t("s")})`}
              </Tooltip>)
            },
      },
      { title: t("End reason"), dataIndex: "e_END_CAUSE_NAME", key: "e_END_CAUSE_NAME", width: 40, fixed: "left", ellipsis: true,
        render: (text, record) => {
          return <Tooltip title={t(record.e_END_CAUSE_NAME)}>{t(record.e_END_CAUSE_NAME)}</Tooltip>
        }
      },
  ], [BASE_URL, audioUrl, isPlaying, isLoadingAudio]);

    const expandedRowRender = (record) => {
      return (
          <Table
            columns={columnsInner}
            dataSource={record.details}
            pagination={false}
            rowClassName="expanded-row"
            rowKey={record => `inner-${record.uniqueId}`}
          />
      );
    };
    
    const [expandedRowKeys, setExpandedRowKeys] = useState([]);

    const onExpand = (expanded, record) => {
      const newExpandedRowKeys = expanded ? [...expandedRowKeys, `main-${record.uniqueId}`] : expandedRowKeys.filter(key => key !== `main-${record.uniqueId}`);
      setExpandedRowKeys(newExpandedRowKeys);
    }
    
    const getRowClassName = (record) => {
      const className = expandedRowKeys.includes(`main-${record.uniqueId}`) ? "expanded-row" : "";
      const isActive = `${BASE_URL}${record.url}` === audioUrl && isPlaying;
      return isActive ? `${className} active-row` : className;
      // return className;
    };

    const onLoadingError = (err) => {
      console.log(err);
      console.log(expandedRowKeys);
      setIsPlaying(false);
    };

    const handleClick = useCallback((text, record) => {
      if(`${BASE_URL}${record.url}` !== audioUrl){
        // setIsPlaying(false);
        // setAudioUrl(`http://localhost:3001/audio?url=${BASE_URL}${record.url}&token=${tokenRef.current}`);
        setPlayAfterLoad(true);
        setAudioUrl(`${BASE_URL}${record.url}`);
        setIsPlayingId(record.uniqueId);
      }else{
        setIsPlaying(!isPlaying);
      };
    }, [audioUrl, isPlaying]);

    const handleIsPlayingChange = (newIsPlaying) => {
      if(newIsPlaying !== isPlaying){
        setIsPlaying(newIsPlaying);
      }
    };

    const handleOnLoadingChange = (newLoadValue) => {
      setLoadAudioPercent(parseInt(newLoadValue, 0))
    };

    useEffect(() => {
      if(loadAudioPercent == 100){
        if(isLoadingAudio){
          setIsLoadingAudio(false);
        };
      };
      if(loadAudioPercent < 100){
        if(!isLoadingAudio){
          setIsLoadingAudio(true);
        };
      }
    }, [loadAudioPercent]);


    const handleCurrentDurationChange = (newCurrentDuration) => {
      setCurrentDuration(newCurrentDuration);
    };

    return (
      <div className="audio-records-main-space">
          <AudioPlayer
            id={1} 
            audioUrl={isSuccess ? audioUrl : DEFAULT_AUDIO_URL}
            playPause={isPlaying} 
            playAfterLoad={playAfterLoad}
            onIsPlayingChange={handleIsPlayingChange}
            onLoadingChange={handleOnLoadingChange}
            onCurrentDurationChange={handleCurrentDurationChange}
            token={store.getState().auth.access}
            onError={onLoadingError}
            />
        <Space size={20} className="audio-records-filters-row" direction="horizontal" >
        <Form>
          <Form.Item 
            rules={[
              {
                type: 'array',
                required: true,
                message: 'Please select a date range!',
              },
            ]}
            style={{marginBottom:"0px"}}>
        <RangePicker
              showTime={{ 
                format: "HH:mm", 
                defaultValue: [
                  dayjs("00:00", "HH:mm"), 
                  dayjs("23:59", "HH:mm")
                ]
              }}
              value={dateRange}
              style={{ minWidth: 200 }}
              disabledDate={disabledDate}
              presets={getPresetRanges()}
              onCalendarChange={(val) => setDateRange(val)}
              onChange={(val) => setDateRangeValue(val)}
            />
            </Form.Item>
        </Form>
        <Select
            value={callType}
            onChange={setCallType}
            placeholder={t("Call type")}
            style={{ minWidth:150, width: 170}}
            options={[
              { value: "inbound", label: t("Inbound calls") },
              { value: "outbound_transfer", label: t("Outbound transfer") },
              { value: "outbound_callback", label: t("Outbound callback") },
            ]}
          />
        <Input
            value={phoneNumber}
            allowClear
            onChange={(e) => setPhoneNumber(e.target.value)}
            placeholder={t("Phone number")}
            prefix={<PhoneOutlined />}
          />
        <Input
            value={agentNumber}
            allowClear
            onChange={(e) => setAgentNumber(e.target.value)}
            placeholder={t("Agent number")}
            prefix={<UserOutlined />}
          />
        <div style={{width:30}}>
            <div hidden={!isFiltered} >
              <Tooltip
                title={t("Clear all filters")}>
                <Button
                  size="middle"
                  type="dashed"
                  icon={<ClearOutlined />}
                  onClick={() => {handleFiltersClear()}}
                />
                </Tooltip>
            </div>
        </div>
        <Button type="primary" onClick={handleSearch} icon={<SearchOutlined />}>{t("Search")}</Button>

        </Space>
      <Row className="audio-records-table-row">
        <Table 
          className="table-audio-records-expandable audio-records-table"
          loading={isLoadingRecords}
          columns={columnsMain}
          dataSource={isSuccess ? data?.records : []}
          expandable={{ expandedRowRender, columnWidth: "10px" }}
          size="small"
          rowClassName={(record) => getRowClassName(record)}
          rowKey={record => `main-${record.uniqueId}`}
          onExpand={onExpand}
          pagination={{
            position: ["none", "bottomCenter"],
            pageSize: limit,
            showSizeChanger: true,
            hideOnSinglePage: false,
            onShowSizeChange: handlePageChange,
            onChange: handlePageChange,
            pageSizeOptions: [10, 20, 50],
            total: data ? data?.count : 0
          }}
        />
      </Row>
    </div>
    )
}

export default AudioRecordsPage;