// usePermission.js
import { useSelector } from 'react-redux';

const usePermission = (requiredPermission) => {
  const permissions = useSelector((state) => state.auth.permissions);
  return permissions?.includes(requiredPermission);
};

const useGroup = (requiredGroup) => {
  const groups = useSelector((state) => state.auth.groups);
  return groups?.includes(requiredGroup);
};


export { usePermission, useGroup };
