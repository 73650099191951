import { useDispatch, useSelector } from "react-redux";
import Page403 from './Page403';
import { useEffect } from 'react';
import { useRefreshTokenMutation } from '../../utils/api/authApi';

import { isLoggedIn, logout } from '../../utils/reducers/authReducer';

export { PrivateRoute };

function PrivateRoute({ children }) {
  const dispatch = useDispatch();
  const loggedIn = useSelector(isLoggedIn);
  const [refreshToken, { isSuccess, isError, isLoading }] = useRefreshTokenMutation();

  useEffect(() => {
    if (!loggedIn) {
      refreshToken().catch(() => {});
    }
  }, [loggedIn, refreshToken]);

  useEffect(() => {
    if (isError) {
      dispatch(logout());
    }
  }, [isError, dispatch]);

  if (!loggedIn && !isSuccess && !isLoading) {
    return <Page403 />;
  }

  return children;
}

