import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { PrivateRoute } from './PrivateRoute';
import PermissionRoute from './PermissionRoute';
import Page404 from './Page404';
import { LoginPage, TicketsPage, TicketPage, HomePage, AudioRecordsPage } from '../';


const MainRouter = () => {
    return (
        <>
            <Routes>
              <Route exact path="/login" element={<LoginPage/>}/>
              <Route exact path="/tickets" element={<PermissionRoute requiredPermission="milk_alliance.view_ticket"><TicketsPage /></PermissionRoute>} />
              <Route exact path="/tickets/:ticketId" element={<PermissionRoute requiredPermission="milk_alliance.view_ticket"><TicketPage /></PermissionRoute>} />
              {/* <Route exact path="/audiorecords" element={<PermissionRoute requiredPermission="auth.add_permission"><AudioRecordsPage /></PermissionRoute>} /> */}
              <Route exact path="/audiorecords" element={<PermissionRoute requiredPermission="authentication.can_access_records"><AudioRecordsPage /></PermissionRoute>} />
              <Route exact path="/" element={<PrivateRoute><HomePage /></PrivateRoute>} />
              <Route exact path="*" element={<Page404/>} /> 
            </Routes>
        </>
    )
}

export default MainRouter
