// PermissionWrapper.jsx
import React from 'react';
import { useSelector } from 'react-redux';
import Page403 from './Page403';
import { isLoggedIn } from '../../utils/reducers/authReducer';


const PermissionRoute = ({ requiredPermission, children }) => {
  const permissions = useSelector((state) => state.auth.permissions);
  const loggedIn = useSelector(isLoggedIn);

  if (!permissions?.includes(requiredPermission)) {
    return <Page403/>
  }

  return <>{children}</>;
};

export default PermissionRoute;
