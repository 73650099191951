import { createSlice } from '@reduxjs/toolkit';
import { ticketsApi } from '../api/ticketsApi';

const initialState = {
  // tickets: [],
  // count: 0,
  // themes: loadTicketsState('themes') || [],
  // statuses: loadTicketsState('statuses') || [],
};

const ticketsSlice = createSlice({
  name: 'tickets',
  initialState,
  reducers: {
    addCommentToTicket: (state, action) => {
      const ticketIndex = state.tickets.findIndex(ticket => ticket.id === action.payload.ticket_id);
      if (ticketIndex !== -1) {
        if (!state.tickets[ticketIndex].comments) {
          state.tickets[ticketIndex].comments = [];
        }
        state.tickets[ticketIndex].comments.push(action.payload);
      }
    },
    addFileToTicket: (state, action) => {
      const ticketIndex = state.tickets.findIndex(
        (ticket) => ticket.id === action.payload.ticket_id
      );
      if (ticketIndex !== -1) {
        if (!state.tickets[ticketIndex].files) {
          state.tickets[ticketIndex].files = [];
        }
        state.tickets[ticketIndex].files.push(action.payload);
      }
    },
    changeStatusOfTicket: (state, action) => {
      const ticketIndex = state.tickets.findIndex(
        (ticket) => ticket.id === action.payload.ticket_id
      );
      if (ticketIndex !== -1) {
        state.tickets[ticketIndex].status = action.payload.status;
        state.tickets[ticketIndex].statusId = action.payload.status_id;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(ticketsApi.endpoints.getTickets.matchFulfilled, (state, action) => {
        state.tickets = action.payload.tickets;
        state.count = action.payload.count;
        // saveTicketsState('tickets', { tickets: state.tickets, count: state.count });
      })
      .addMatcher(ticketsApi.endpoints.getThemes.matchFulfilled, (state, action) => {
        state.themes = action.payload;
        // saveTicketsState('themes', state.themes);
      })
      .addMatcher(ticketsApi.endpoints.getStatuses.matchFulfilled, (state, action) => {
        state.statuses = action.payload;
        // saveTicketsState('statuses', state.statuses);
      });
  },
});

export const { addCommentToTicket, addFileToTicket, changeStatusOfTicket } = ticketsSlice.actions;

export default ticketsSlice.reducer;
