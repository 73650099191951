import { configureStore } from '@reduxjs/toolkit';
import { authApi } from './api/authApi';
import authReducer from './reducers/authReducer';
import { ticketsApi } from './api/ticketsApi';
import ticketsReducer from './reducers/ticketsReducer';
import { audioRecordsApi } from './api/audioRecordsApi';
import audioRecordsReducer from './reducers/audioRecordsReducer';
import { loadState } from './localStorage';
import { userApi } from './api/userApi';
import userReducer from './reducers/userReducer';


const preloadedState = {
  auth: loadState('auth') || undefined,
  user: loadState('user') || undefined,
};

const middleware = [
  authApi.middleware, 
  ticketsApi.middleware, 
  audioRecordsApi.middleware, 
  userApi.middleware,
];

const store = configureStore({
  reducer: {
    auth: authReducer,
    tickets: ticketsReducer,
    audioRecords: audioRecordsReducer,
    user: userReducer,
    [authApi.reducerPath]: authApi.reducer,
    [ticketsApi.reducerPath]: ticketsApi.reducer,
    [audioRecordsApi.reducerPath]: audioRecordsApi.reducer,
    [userApi.reducerPath]: userApi.reducer,

  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(middleware),
  preloadedState,
});

export default store;
