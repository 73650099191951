
const en = {
  "Log in": "Log in",
  "Issue Management System": "Issue Management System",
  "Username": "Username",
  "Password": "Password",
  "Log out": "Log out",
  "Tickets": "Tickets",
  "Audiorecords": "Audiorecords",
  "Inbound calls": "Inbound calls",
  "Outbound calls": "Outbound calls",
  "Start date": "Start date",
  "End date": "End date",
  "Today": "Today",
  "Yesterday": "Yesterday",
  "Yesterday+today": "Yesterday+today",
  "Current week": "Current week",
  "Current month": "Current month",
  "Previous week": "Previous week",
  "Previous month": "Previous month",
  "Phone number": "Phone number",
  "Theme": "Theme",
  "Sub theme": "Sub theme",
  "Status": "Status",
  "New": "New",
  "In progress": "In progress",
  "Closed": "Closed",
  "Product name": "Product name",
  "Production date": "Production date",
  "Batch": "Batch",
  "Manufacturer number": "Manufacturer number",
  "Name": "Name",
  "Phone": "Phone",
  "Call date": "Call date",
  "Agent": "Agent",
  "Critical": "Critical",
  "Yes": "Yes",
  "No": "No",
  "Shop city": "Shop city",
  "Shop name": "Shop name",
  "Shop address": "Shop address",
  "ID": "ID",
  "Product": "Product",
  "City": "City",
  "Description": "Description",
  "Comment": "Comment",
  "Comments": "Comments",
  "Add comment": "Add comment",
  "Show all": "Show all",
  "Hide": "Hide",
  "Write your comment here": "Write your comment here",
  "Call audio record": "Call audio record",
  "added a comment": "added a comment",
  "Change status to:": "Change status to:",
  "Change status?": "Change status?",
  "Are you sure to the status of this task?": "Are you sure to the status of this task?",
  "Ticket": "Ticket",
  "Download": "Download",
  "Play": "Play",
  "Pause": "Pause",
  "Language": "Language",
  "English": "English",
  "Українська": "Ukrainian",
  "Sorry, the page you visited does not exist.": "Sorry, the page you visited does not exist.",
  "Return": "Return",
  "Sorry, you are not authorized to access this page.": "Sorry, you are not authorized to access this page.",
  "Sorry, this page is under construction.": "Sorry, this page is under construction.",
  "Clear all filters": "Clear all filters",
  "Status changes history": "Status changes history",
  "Copy ticket link to clipboard": "Copy ticket link to clipboard",
  "Show test records. Checkbox visible only for admin group.": "Show test records. Checkbox visible only for admin group.",
  "Show test records": "Show test records",
  "Test": "Test",
  "Outbound transfer": "Outbound transfer",
  "Outbound callback": "Outbound callback",
  "Start call": "Start call",
  "End call": "End call",
  "Duration": "Duration",
  "s": "s",
  "Call type": "Call type",
  "Agent number": "Agent number",
  "Start": "Start",
  "End": "End",
  "Agent name": "Agent name",
  "Wait": "Wait",
  "Talk": "Talk",
  "Hold": "Hold",
  "End reason": "End reason",
  "Search": "Search",
  "Last 7 days": "Last 7 days",
  "Call audio record will be available soon.": "Call audio record will be available soon.",
  "The call audio record will be available approximately two hours after its completion.": "The call audio record will be available approximately two hours after its completion.",
  "The caller release the call": "The caller release the call",
  "The system release the call": "The system release the call",
  "The targeted pilot is closed, the call is redirected to an other pilot.": "The targeted pilot is closed, the call is redirected to an other pilot.",
  "The targeted pilot is saturated (its regular queue(s) is saturated), the call is redirected to an other pilot.": "The targeted pilot is saturated (its regular queue(s) is saturated), the call is redirected to an other pilot.",
  "The waiting time overflow timer redirected the call to a pilot or in case of an ACR call, the used selection rule redirected the selection rule redirected the call to a pilot.": "The waiting time overflow timer redirected the call to a pilot or in case of an ACR call, the used selection rule redirected the selection rule redirected the call to a pilot.",
  "The ringing overflow timer redirected the call to a pilot.": "The ringing overflow timer redirected the call to a pilot.",
  "The targeted pilot is closed, the call is redirected to an agent.": "The targeted pilot is closed, the call is redirected to an agent.",
  "The targeted pilot is saturated, the call is redirected to an agent.": "The targeted pilot is saturated, the call is redirected to an agent.",
  "The waiting time overflow timer redirected the call to an agent .": "The waiting time overflow timer redirected the call to an agent .",
  "The ringing overflow timer redirected the call to an agent.": "The ringing overflow timer redirected the call to an agent.",
  "The targeted pilot is closed, the call is redirected to an address (not an acd device).": "The targeted pilot is closed, the call is redirected to an address (not an acd device).",
  "The targeted pilot is saturated, the call is redirected to an address (not an acd device).": "The targeted pilot is saturated, the call is redirected to an address (not an acd device).",
  "The waiting time overflow timer redirected the call to an address (not an acd device).": "The waiting time overflow timer redirected the call to an address (not an acd device).",
  "The ringing overflow timer redirected the call to an address (not an acd device).": "The ringing overflow timer redirected the call to an address (not an acd device).",
  "The call was ringing an agent, another agent of the different processing group picked'up the call.": "The call was ringing an agent, another agent of the different processing group picked'up the call.",
  "The call was ringing an agent when a non monitored device picked?up the call.": "The call was ringing an agent when a non monitored device picked?up the call.",
  "An acd_call was transferred to a pilot by an agent.": "An acd_call was transferred to a pilot by an agent.",
  "An acd_call was transferred to an agent of the same processing group by an agent.": "An acd_call was transferred to an agent of the same processing group by an agent.",
  "An acd_call was transferred to an agent belonging to a different processing group of agent?s one who performed the transfer": "An acd_call was transferred to an agent belonging to a different processing group of agent?s one who performed the transfer",
  "An acd_call was transferred by an agent to an address (non acd device).": "An acd_call was transferred by an agent to an address (non acd device).",
  "A non acd_call was transferred by an agent to a pilot.": "A non acd_call was transferred by an agent to a pilot.",
  "A non acd_call was transferred by an agent to an agent belonging to the same processing group.": "A non acd_call was transferred by an agent to an agent belonging to the same processing group.",
  "A non acd_call was transferred by an agent to an other agent not belonging to the same processing group (or not assigned).": "A non acd_call was transferred by an agent to an other agent not belonging to the same processing group (or not assigned).",
  "A non acd_call was transferred by an agent to an address (not an acd device).": "A non acd_call was transferred by an agent to an address (not an acd device).",
  "A non_acd call ringing an agent was picked?up by a non monitored device.": "A non_acd call ringing an agent was picked?up by a non monitored device.",
  "Communication ends due to an abnormal cause : coupler of agent's set was unplugged, agent?s phone set was unplugged...).": "Communication ends due to an abnormal cause : coupler of agent's set was unplugged, agent?s phone set was unplugged...).",
  "Called released the call (mainly an agent or a non monitored device).": "Called released the call (mainly an agent or a non monitored device).",
  "Acd call rejected. An acd call is presented to a pilot, no resource is available to handle the call (See Note 1).": "Acd call rejected. An acd call is presented to a pilot, no resource is available to handle the call (See Note 1).",
  "The call was ringing an agent, an other agent of the same processing group picked?up the call.": "The call was ringing an agent, an other agent of the same processing group picked?up the call.",
  "An acd call is distributed on a resource which is not an agent.": "An acd call is distributed on a resource which is not an agent.",
  "An acd call is picked?up by a non assigned agent (the call becomes a non?acd call).": "An acd call is picked?up by a non assigned agent (the call becomes a non?acd call).",
  "An acd_call was transferred to a non assigned agent, the call becomes a non acd call": "An acd_call was transferred to a non assigned agent, the call becomes a non acd call",
  "A call was released due to the agent's unplugged phone set": "A call was released due to the agent's unplugged phone set",
  "A call connected to an IVR while waiting in queue (IVR in queue feature) was extracted from the queue by the IVR.": "A call connected to an IVR while waiting in queue (IVR in queue feature) was extracted from the queue by the IVR.",
  "For an ACR call, a selection mode or an attribute list (skill, expert, character) change happened in waiting room.": "For an ACR call, a selection mode or an attribute list (skill, expert, character) change happened in waiting room.",
  "An ACR rotation happened, the new rang agent and the previous one belong to the same PG.": "An ACR rotation happened, the new rang agent and the previous one belong to the same PG.",
  "An ACR rotation happened, the new rang agent and the previous one belong to two different PG.": "An ACR rotation happened, the new rang agent and the previous one belong to two different PG.",
  "Call is suspended.": "Call is suspended.",
  "Ringing rotation for an ACR call distributed to a dedicated pilot": "Ringing rotation for an ACR call distributed to a dedicated pilot",
  "An ACR call sent to a dedicated pilot (ACR networking) is not served and comes back on local node.": "An ACR call sent to a dedicated pilot (ACR networking) is not served and comes back on local node.",
  "An enquiry call is done towards an agent. When the caller releases this enquiry call by a transfert, communication ticket for th": "An enquiry call is done towards an agent. When the caller releases this enquiry call by a transfert, communication ticket for th",

}
   

const ua = {
  "Log in": "Увійти",
  "Issue Management System": "Система управління зверненнями",
  "Username": "Ім'я користувача",
  "Password": "Пароль",
  "Log out": "Вийти",
  "Tickets": "Скарги",
  "Audiorecords": "Аудіозаписи",
  "Inbound calls": "Вхідні дзвінки",
  "Outbound calls": "Вихідні дзвінки",
  "Start date": "Дата початку",
  "End date": "Дата закінчення",
  "Today": "Сьогодні",
  "Yesterday": "Вчора",
  "Yesterday+today": "Вчора+сьогодні",
  "Current week": "Поточний тиждень",
  "Current month": "Поточний місяць",
  "Previous week": "Попередній тиждень",
  "Previous month": "Попередній місяць",
  "Phone number": "Номер телефону",
  "Theme": "Тема",
  "Sub theme": "Підтема",
  "Status": "Статус",
  "New": "Нова",
  "In progress": "В роботі",
  "Closed": "Завершена",
  "Product name": "Назва продукту",
  "Production date": "Дата виробництва",
  "Batch": "Партія",
  "Manufacturer number": "Номер виробника",
  "Name": "Ім'я",
  "Phone": "Телефон",
  "Call date": "Дата дзвінка",
  "Agent": "Агент",
  "Critical": "Критичність",
  "Yes": "Так",
  "No": "Ні",
  "Shop city": "Місто магазину",
  "Shop name": "Назва магазину",
  "Shop address": "Адреса магазину",
  "ID": "ID",
  "Product": "Продукт",
  "City": "Місто",
  "Description": "Опис",
  "Comment": "Коментар",
  "Comments": "Коментарі",
  "Add comment": "Додати коментар",
  "Show all": "Показати усі",
  "Hide": "Сховати",
  "Write your comment here": "Напишіть свій коментар тут",
  "Call audio record": "Аудіозапис дзвінка",
  "added a comment": "додав(ла) коментар",
  "Change status to:": "Змінити статус на:",
  "Change status?": "Змінити статус?",
  "Are you sure to the status of this task?": "Ви впевнені, що хочете змінити статус цієї скарги?",
  "Ticket": "Скарга",
  "Download": "Завантажити",
  "Play": "Відтворити",
  "Pause": "Пауза",
  "Language": "Мова",
  "English": "Англійська",
  "Українська": "Українська",
  "Sorry, the page you visited does not exist.": "Вибачте, але сторінка, яку ви відвідали, не існує.",
  "Return": "Повернутись",
  "Sorry, you are not authorized to access this page.": "Вибачте, але у вас немає доступу до цієї сторінки.",
  "Sorry, this page is under construction.": "Вибачте, але ця сторінка знаходиться в розробці.",
  "Clear all filters": "Очистити всі фільтри",
  "Status changes history": "Історія зміни статусів",
  "Copy ticket link to clipboard": "Скопіювати посилання на скаргу",
  "Show test records. Checkbox visible only for admin group.": "Показати тестові записи. Checkbox видимий тільки для групи адміністраторів.",
  "Show test records": "Показати тестові записи",
  "Test": "Тест",
  "Outbound transfer": "Переключення",
  "Outbound callback": "Передзвони",
  "Start call": "Початок дзвінка",
  "End call": "Кінець дзвінка",
  "Duration": "Тривалість",
  "s": "с",
  "Call type": "Тип дзвінка",
  "Agent number": "Номер агента",
  "Start": "Початок",
  "End": "Кінець",
  "Agent name": "Ім'я агента",
  "Wait": "Очікування",
  "Talk": "Розмова",
  "Hold": "Утримання",
  "End reason": "Причина завершення",
  "Search": "Пошук",
  "Last 7 days": "Останні 7 днів",
  "Call audio record will be available soon.": "Аудіозапис дзвінка буде доступний найближчим часом.",
  "The call audio record will be available approximately two hours after its completion.": "Аудіозапис дзвінка буде доступний приблизно через дві години після його завершення.",
  "The caller release the call": "Абонент завершив дзвінок",
  "The system release the call": "Система завершила дзвінок",
  "The targeted pilot is closed, the call is redirected to an other pilot.": "Цільовий пілот закритий, дзвінок перенаправляється на інший пілот.",
  "The targeted pilot is saturated (its regular queue(s) is saturated), the call is redirected to an other pilot.": "Цільовий пілот переповнений (його звичайна черга(и) переповнена), дзвінок перенаправляється на інший пілот.",
  "The waiting time overflow timer redirected the call to a pilot or in case of an ACR call, the used selection rule redirected the selection rule redirected the call to a pilot.": "Таймер переповнення часу очікування перенаправив дзвінок на пілот, або у разі ACR-дзвінка, використано правило вибору, яке перенаправило дзвінок на пілот.",
  "The ringing overflow timer redirected the call to a pilot.": "Таймер переповнення дзвінка перенаправив дзвінок на пілот.",
  "The targeted pilot is closed, the call is redirected to an agent.": "Цільовий пілот закритий, дзвінок перенаправляється на оператора.",
  "The targeted pilot is saturated, the call is redirected to an agent.": "Цільовий пілот переповнений, дзвінок перенаправляється на оператора.",
  "The waiting time overflow timer redirected the call to an agent .": "Таймер переповнення часу очікування перенаправив дзвінок на оператора.",
  "The ringing overflow timer redirected the call to an agent.": "Таймер переповнення дзвінка перенаправив дзвінок на оператора.",
  "The targeted pilot is closed, the call is redirected to an address (not an acd device).": "Цільовий пілот закритий, дзвінок перенаправляється на адресу (не на пристрій ACD).",
  "The targeted pilot is saturated, the call is redirected to an address (not an acd device).": "Цільовий пілот переповнений, дзвінок перенаправляється на адресу (не на пристрій ACD).",
  "The waiting time overflow timer redirected the call to an address (not an acd device).": "Таймер переповнення часу очікування перенаправив дзвінок на адресу (не на пристрій ACD).",
  "The ringing overflow timer redirected the call to an address (not an acd device).": "Таймер переповнення дзвінка перенаправивдзвінок на адресу (не на пристрій ACD).",
  "The call was ringing an agent, another agent of the different processing group picked'up the call.": "Дзвінок дзвонив оператору, інший оператор із різної групи обробки відповів на дзвінок.",
  "The call was ringing an agent when a non monitored device picked?up the call.": "Дзвінок дзвонив оператору, коли його підхопив несупроводжуваний пристрій.",
  "An acd_call was transferred to a pilot by an agent.": "ACD-дзвінок був перенаправлений на пілота оператором.",
  "An acd_call was transferred to an agent of the same processing group by an agent.": "ACD-дзвінок був перенаправлений на оператора зі своєї ж групи обробки оператором.",
  "An acd_call was transferred to an agent belonging to a different processing group of agent?s one who performed the transfer": "ACD-дзвінок був перенаправлений на оператора, що належить до іншої групи обробки, аніж оператор, який виконав перенаправлення",
  "An acd_call was transferred by an agent to an address (non acd device).": "ACD-дзвінок був перенаправлений оператором на адресу (не на пристрій ACD).",
  "A non acd_call was transferred by an agent to a pilot.": "Не ACD-дзвінок був перенаправлений оператором на пілота.",
  "A non acd_call was transferred by an agent to an agent belonging to the same processing group.": "Не ACD-дзвінок був перенаправлений оператором на оператора, що належить до тієї ж групи обробки.",
  "A non acd_call was transferred by an agent to an other agent not belonging to the same processing group (or not assigned).": "Не ACD-дзвінок був перенаправлений оператором на іншого оператора, який не належить до тієї ж групи обробки (або не призначений).",
  "A non acd_call was transferred by an agent to an address (not an acd device).": "Не ACD-дзвінок був перенаправлений оператором на адресу (не на пристрій ACD).",
  "A non_acd call ringing an agent was picked?up by a non monitored device.": "Не ACD-дзвінок, який дзвонив оператору, був підхоплений несупроводжуваним пристроєм.",
  "Communication ends due to an abnormal cause : coupler of agent's set was unplugged, agent?s phone set was unplugged...).": "Комунікація закінчується внаслідок неприродної причини: вилучено з'єднувач набору оператора, вилучено телефонний набір оператора...).",
  "Called released the call (mainly an agent or a non monitored device).": "Викликаючий завершив дзвінок (головним чином, оператор або несупроводжуваний пристрій).",
  "Acd call rejected. An acd call is presented to a pilot, no resource is available to handle the call (See Note 1).": "ACD-дзвінок відхилено. ACD-дзвінок подається на пілота, немає доступних ресурсів для обробки дзвінка (див. Примітку 1).",
  "The call was ringing an agent, an other agent of the same processing group picked?up the call.": "Дзвінок дзвонив оператору, інший оператор зі тієї ж групи обробки підхопив дзвінок.",
  "An acd call is distributed on a resource which is not an agent.": "ACD-дзвінок розподіляється на ресурс, який не є оператором.",
  "An acd call is picked?up by a non assigned agent (the call becomes a non?acd call).": "ACD-дзвінок підхоплюється не призначеним оператором (дзвінок стає не-ACD дзвінком).",
  "An acd_call was transferred to a non assigned agent, the call becomes a non acd call": "ACD-дзвінок був перенаправлений на не призначеного оператора, дзвінок стає не-ACD дзвінком",
  "A call was released due to the agent's unplugged phone set": "Дзвінок було звільнено через відключення телефонного набору оператора",
  "A call connected to an IVR while waiting in queue (IVR in queue feature) was extracted from the queue by the IVR.": "Дзвінок, що підключений до IVR під час очікування у черзі (функція IVR у черзі), був вилучений з черги саме IVR.",
  "For an ACR call, a selection mode or an attribute list (skill, expert, character) change happened in waiting room.": "Для ACR-дзвінка в режимі очікування відбулась зміна режиму вибору або списку атрибутів (навички, експертність, характер).",
  "An ACR rotation happened, the new rang agent and the previous one belong to the same PG.": "Відбулась ротація ACR, новий обраний оператор і попередній належать до однієї й тієї ж групи обробки.",
  "An ACR rotation happened, the new rang agent and the previous one belong to two different PG.": "Відбулась ротація ACR, новий обраний оператор і попередній належать до двох різних груп обробки.",
  "Call is suspended.": "Дзвінок призупинено.",
  "Ringing rotation for an ACR call distributed to a dedicated pilot": "Обертання дзвінка для ACR, розподіленого на призначений пілот.",
  "An ACR call sent to a dedicated pilot (ACR networking) is not served and comes back on local node.": "ACR-дзвінок, надісланий на призначений пілот (мережевий ACR), не обслуговується і повертається на локальний вузол.",
  "An enquiry call is done towards an agent. When the caller releases this enquiry call by a transfert, communication ticket for th": "Запитувальний дзвінок адресований оператору. Коли викликаючий відпускає цей запитувальний дзвінок шляхом перенаправлення, комунікаційний квиток для нього"
}

export { en, ua };