import React from 'react'
import { useNavigate } from 'react-router-dom';
import { Result, Button } from 'antd';
import { useTranslation } from 'react-i18next';


const Page404 = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    return (
        <Result
          status="404"
          title="404"
          subTitle={t("Sorry, the page you visited does not exist.")}
          extra={<Button type="primary" onClick={()=>{navigate('/')}}>{t("Return")}</Button>}
  />
      )
}

export default Page404