// localStorage.js
export const saveState = (key, state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(key, serializedState);
  } catch (error) {
    console.error('Error saving state to local storage:', error);
  }
};

export const loadState = (key) => {
  try {
    const serializedState = localStorage.getItem(key);
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (error) {
    console.error('Error loading state from local storage:', error);
    return undefined;
  }
};

export const removeState = (key) => {
  try {
    localStorage.removeItem(key);
  } catch (error) {
    console.error('Error removing state from local storage:', error);
  }
};

export const loadTicketsState = (key) => {
  try {
    const serializedState = localStorage.getItem(key);
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (error) {
    console.error('Error loading state from local storage:', error);
    return undefined;
  }
};

export const saveTicketsState = (key, state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(key, serializedState);
  } catch (error) {
    console.error('Error saving state to local storage:', error);
  }
};

export const removeTicketsState = (key) => {
  try {
    localStorage.removeItem(key);
  } catch (error) {
    console.error('Error removing state from local storage:', error);
  }
};
